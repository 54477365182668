import { render, staticRenderFns } from "./platForm.vue?vue&type=template&id=3acd704c&scoped=true"
import script from "./platForm.vue?vue&type=script&lang=js"
export * from "./platForm.vue?vue&type=script&lang=js"
import style0 from "./platForm.vue?vue&type=style&index=0&id=3acd704c&prod&lang=less&scoped=true"
import style1 from "./platForm.vue?vue&type=style&index=1&id=3acd704c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3acd704c",
  null
  
)

export default component.exports